import React from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const CareerPositions = () => {
	const allPagesContent = useSelector((state) => state.pages.pages)
    const cPositionContent = allPagesContent.find((page) => page.slug === 'career-positions')
	const isPageLoading = useSelector((state) => state.pages.isLoading)
	return (
		isPageLoading ?
			<div className='spinner-wrapper'> 
				<Spinner animation="border" variant="info" />
			</div>
		:
		<div className='main-text-section'>
			<Container>
				<Row>
					<Col>
						<h2>Career Positions</h2>
						<div dangerouslySetInnerHTML={{ __html: cPositionContent?.content?.rendered }}></div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default CareerPositions