import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row, Spinner } from 'react-bootstrap';

function Presentations() {
    const allPagesContent = useSelector((state) => state.pages.pages)
    const presentationsContent = allPagesContent.find((page) => page.slug === 'presentations')
	const isPageLoading = useSelector((state) => state.pages.isLoading)
    return (
		isPageLoading ?
			<div className='spinner-wrapper'> 
				<Spinner animation="border" variant="info" />
			</div>
		:
        <div className='main-text-section'>
			<Container>
				<Row>
					<Col>
						<h2>Presentations</h2>
						<div dangerouslySetInnerHTML={{ __html: presentationsContent?.content?.rendered }}></div>
					</Col>
				</Row>
			</Container>
		</div>
    )
}

export default Presentations