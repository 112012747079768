import React from 'react'
import fLogo from '../../../images/flogo.png'
import { Col, Container, Row, Navbar, Nav, NavDropdown, Button, Form } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className='site-footer'>
                <Container data-aos="fade-up">
                    <Row>
                        <Col lg={7}>
                            <img src={fLogo} alt='footer logo' />
                            <p>Dr. Liana Lianov, MD, MPH, FACLM, FACPM, DipABLM, is a visionary global leader in lifestyle medicine and positive health. As the driving force behind the development of the groundbreaking lifestyle medicine core competencies, Dr. Lianov has played a pivotal role in shaping the future of healthcare. <a href="/about">Read More</a></p>
                        </Col>
                        <Col lg={{span: 4, offset: 1}}>
                            <h6>Quick Links</h6>
                            <ul>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/speaking-consulting">Speaking and Consulting</Link></li>
                                <li><Link to="/achievements">Achievements</Link></li>
                                <li><Link to="/publications">Publications</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='site-footer-bottom'>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <p>©2024 Dr. Liana Lianov. All rights reserved</p>
                        </Col>
                        <Col lg={{span: 4, offset: 1}}>
                            <p><Link to="/terms-and-conditions">Terms & Conditions</Link> | <Link to="/privacy-policy">Privacy policy</Link></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer