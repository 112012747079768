import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

const GphiPage = () => {
	return (
		<div className='main-text-section'>
			<Container>
				<Row>
					<Col>
						<h2>GPHIs</h2>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default GphiPage