import React from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Achievements from '../components/Achievements';
import { useSelector } from 'react-redux';

function AchievementsPage() {
	const isPageLoading = useSelector((state) => state.pages.isLoading)
	return (
		isPageLoading ?
			<div className='spinner-wrapper'> 
				<Spinner animation="border" variant="info" />
			</div>
		:
		<Achievements />
	)
}

export default AchievementsPage