import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ProfileImage from '../images/profile.png'
import CaseImage from '../images/case.png'
import BookImage from '../images/book.png'
import CupImage from '../images/cup.png'
import { useSelector } from 'react-redux'

const Achievements = () => {
	const allPagesContent = useSelector((state) => state.pages.pages)
    const homeContent = allPagesContent.find((page) => page.slug === 'home')
	return (
		<div className='achievements main-text-section'>
			<Container>
				<Row>
					{/* <Col className='align-content-center' lg={6} data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="500">
						<h2><strong>Achievements</strong></h2>
						<p className='para'>Discover the milestones and accolades that define Dr. Liana Lianov's illustrious career. This section highlights her significant contributions to lifestyle medicine and positive health, underscoring her dedication and leadership in the field.</p>
						<h5>Major Accomplishments</h5>
						<p className='para'>Explore a detailed showcase of Dr. Lianov’s achievements, including prestigious awards, influential roles, and groundbreaking projects. Each achievement reflects her commitment to advancing healthcare through innovative approaches and exceptional leadership.</p>
						<Link to="/" className='btn btn-primary'>Get Started</Link>
					</Col> */}
					<Col lg={6}>
						<div dangerouslySetInnerHTML={{ __html: homeContent?.acf?.achievements_text }}></div>
						{homeContent?.acf?.achievements_button !== '' &&<Link to={homeContent?.acf?.achievements_button_link } className='btn btn-primary'>{homeContent?.acf?.achievements_button}</Link>}
					</Col>
					<Col lg={6} data-aos="zoom-out" data-aos-offset="200" data-aos-delay="200" data-aos-easing="ease-in-sine" data-aos-duration="500">
						<ul className='achievement-box'>
							<li>
								<Link to="/presentations">
									<Image src={homeContent?.acf?.presentations_image} alt='Presentations' />
									<h5>{homeContent?.acf?.presentations_text}</h5>
								</Link>
							</li>
							<li>
								<Link to="/thought-leadership">
									<Image src={homeContent?.acf?.thought_leadership_image} alt='Thought Leadership' />
									<h5>{homeContent?.acf?.thought_leadership_text}</h5>
								</Link>
							</li>
							<li>
								<Link to="/career-positions">
									<Image src={homeContent?.acf?.career_positions_image} alt='Career positions' />
									<h5>{homeContent?.acf?.career_positions_text}</h5>
								</Link>
							</li>
							<li>
								<Link to="/awards">
									<Image src={homeContent?.acf?.awards_image} alt='Awards' />
									<h5>{homeContent?.acf?.awards_text}</h5>
								</Link>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Achievements