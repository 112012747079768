import React from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SpeakingConsultingPage = () => {
	const allPagesContent = useSelector((state) => state.pages.pages)
    const speakingContent = allPagesContent.find((page) => page.slug === 'speaking-consulting')
	const isPageLoading = useSelector((state) => state.pages.isLoading)
	return (
		isPageLoading ?
			<div className='spinner-wrapper'> 
				<Spinner animation="border" variant="info" />
			</div>
		:
			<div className='main-text-section'>
				<Container>
					<Row>
						<Col>
							<h2>Speaking & Consulting</h2>
							<div dangerouslySetInnerHTML={{ __html: speakingContent?.content?.rendered }}></div>
						</Col>
					</Row>
				</Container>
			</div>
	)
}

export default SpeakingConsultingPage