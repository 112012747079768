import React from 'react'
import hLogo from '../../../images/hlogo.png'
import { Col, Container, Row, Navbar, Nav, NavDropdown, Button, Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';

const Header = () => {
    const pages = useSelector((state) => state.pages.pages)
    const setActive = ({ isActive }) => isActive ? "nav-link active" : "nav-link";
    const setActiveContact = ({ isActive }) => isActive ? "btn btn-contact" : "btn btn-contact";
    return (
        <div className='site-header'>
            <Navbar expand="lg" className='site-navigation'>
                <Container>
                    <Navbar.Brand>
                        <NavLink to="/"><img src={hLogo} alt='header logo' /></NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="my-2 my-lg-0 align-items-center" navbarScroll>
                            { pages.length > 0 ? pages?.map((page, index) => {
                                // console.log(index)
                                if (page.slug !== 'home' && page.slug !== 'privacy-policy' && page.slug !== 'terms-and-conditions' && page.slug !== 'awards' && page.slug !== 'presentations' && page.slug !== 'career-positions' && page.slug !== 'thought-leadership' && page.slug !== 'book-chapters' && page.slug !== 'articles') {
                                    const originalString = page.title.rendered
                                    const replaceTitle = originalString.replace(/&amp;/g, '&');
                                    const pageLink = new URL(`${page.link}`)
                                    const pageLinkHost = pageLink.hostname
                                    console.log(index)
                                    return (
                                        <NavLink key={page.id} className={page.slug === 'contact-us'? setActiveContact : setActive} to={window.location.hostname === pageLinkHost ? page.slug : page.link} target={window.location.hostname === pageLinkHost ? "_self" : "_blank"}>{replaceTitle}</NavLink>
                                    )
                                }
                            }) : `server not connected`}
                            {/* <NavLink className={setActiveContact} to="/contact-us">Contact Us</NavLink>
                            <NavLink className={setActive} to="/my-happy-avatar">My happy avatar</NavLink>
                            <NavLink className={setActive} to="/gphi">GPHI</NavLink>
                            <NavLink className={setActive} to="/achievements">Achievements</NavLink>
                            <NavLink className={setActive} to="/publications">Publications</NavLink>
                            <NavLink className={setActive} to="/speaking-consulting">Speaking &amp; Consulting</NavLink>
                            <NavLink className={setActive} to="/about">About</NavLink> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header